<template>
    <div class="container">
        <div class="row">
        
            <div class="card mb-xl-0">
                <div class="card-header">
                    <h3 class="card-title">Payments </h3>
                </div>
                <div class="card-body">
                    <div class="panel panel-primary">
                        <div class=" tab-menu-heading border-0 pl-0 pr-0 pt-0">
                            <div class="tabs-menu1 ">
                                <!-- Tabs -->
                                <ul class="nav panel-tabs">
                                    <li><a href="#tab-card" v-on:click="setPayType('simplepay')" class="active" data-toggle="tab">Credit/ Debit Card</a></li>
                                    <li><a href="#tab-forward" v-on:click="setPayType('simpleforward-referencepay')" data-toggle="tab" class="">I pay with advanced payment</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="panel-body tabs-menu-body pl-0 pr-0 border-0">
                            <div class="tab-content">
                                <div class="tab-pane active" id="tab-card">
                                    <img src="assets/images/otp.png" alt="OTP Simple" title="OTP Simple" />
                                </div>
                                <div class="tab-pane" id="tab-forward">
                                    <h4><img src="assets/images/payment_transfer.png" alt="I pay with advanced payment" title="I pay with advanced payment" />I pay with advanced payment</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="tab-content card-body border mb-0 b-0">
                        
                            <div class="panel panel-primary">

                                <div class="panel-body tabs-menu-body pl-0 pr-0 border-0">
                                    <div class="tab-content">
                                        <div class="tab-pane active " id="tab5">
                                            <ValidationObserver  ref="observer" tag="form" @submit.prevent="onSubmit" v-slot="{ invalid }">
                                                <validation-provider rules="required|min:3|max:50" v-slot="{ errors }">
                                                    <div class="form-group">
                                                        <label class="form-label">Name <span class="text-red">*</span></label>
                                                        <input type="text" class="form-control" v-model="paymentsInfo.name" id="name" placeholder="Name">
                                                        <span class="text-red">{{ errors[0] }}</span>
                                                    </div>
                                                </validation-provider>
                                            
                                                <validation-provider rules="required|email" v-slot="{ errors }">
                                                    <div class="form-group">
                                                        <label class="form-label">E-mail<span class="text-red">*</span></label>
                                                        <input type="text" class="form-control" v-model="paymentsInfo.email" placeholder="E-mail" name="email">
                                                        <span class="text-red">{{ errors[0] }}</span>
                                                    </div>
                                                </validation-provider>

                                                <validation-provider rules="required|email" v-slot="{ errors }">
                                                    <div class="form-group">
                                                        <label class="form-label">E-mail confirmation<span class="text-red">*</span></label>
                                                        <input type="text" class="form-control" v-model="paymentsInfo.emailConfirmation" placeholder="E-mail confirmation" name="email">
                                                        <span class="text-red">{{ errors[0] }}</span>
                                                    </div>
                                                </validation-provider>

                                                <validation-provider rules="min:3|max:50" v-slot="{ errors }">
                                                    <div class="form-group">
                                                        <label class="form-label">Tax number</label>
                                                        <input type="text" class="form-control"  v-model="paymentsInfo.taxnumber" placeholder="Tax number" name="taxnumber">
                                                        <span class="text-red">{{ errors[0] }}</span>
                                                    </div>
                                                </validation-provider>

                                                <div class="row">
                                                
                                                    <div class="col-sm-6">
                                                    <validation-provider rules="required" v-slot="{ errors }">
                                                        <div class="form-group">
                                                            <label class="form-label">Country <span class="text-red">*</span></label>
                                                            <select class="form-control"  v-model="paymentsInfo.country" placeholder="Country" name="country">
                                                                <option  v-for="option in pCountries" :value="option.Code">{{option.Name}}</option>
                                                            </select>
                                                            <span class="text-red">{{ errors[0] }}</span>
                                                        </div>
                                                    </validation-provider>
                                                    </div>
                                                
                                                    <div class="col-sm-6 ">
                                                        <validation-provider rules="required|max:10" v-slot="{ errors }">
                                                        <div class="form-group">
                                                            <label class="form-label">Zip Code <span class="text-red">*</span></label>
                                                            <input type="text" class="form-control"  v-model="paymentsInfo.zipcode" placeholder="Zip code" name="zipcode">
                                                            <span class="text-red">{{ errors[0] }}</span>
                                                        </div>
                                                        </validation-provider>
                                                    </div>
                                                </div>

                                                <validation-provider rules="required|max:20" v-slot="{ errors }">
                                                    <div class="form-group">
                                                        <label class="form-label">City <span class="text-red">*</span></label>
                                                        <input type="text" class="form-control"  v-model="paymentsInfo.city" placeholder="City" name="city">
                                                        <span class="text-red">{{ errors[0] }}</span>
                                                    </div>
                                                </validation-provider>

                                                <div class="row">
                                                    <div class="col-12">
                                                        <validation-provider rules="required|max:20" v-slot="{ errors }">
                                                        <div class="form-group">
                                                            <label class="form-label">Street <span class="text-red">*</span></label>
                                                            <input type="text" class="form-control"  v-model="paymentsInfo.address" placeholder="Address" name="address">
                                                            <span class="text-red">{{ errors[0] }}</span>
                                                        </div>
                                                        </validation-provider>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <label class="form-label">Phone number</label>
                                                    <input type="text" class="form-control"  v-model="paymentsInfo.phone" placeholder="Phone number" name="phone">
                                                </div>

                                                <div class="form-group row clearfix">
                                                    <div class="col-lg-12">
                                                        <ValidationProvider :rules="{ required: { allowFalse: false } }"  v-slot="{ errors }">
                                                            <label class="custom-control mt-4 custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" name="Accept Conditions"  v-model="paymentsInfo.acceptConditions" >
                                                                <span class="custom-control-label text-dark pl-2"> I accept <a class="terms" target="_blank" :href="asset('assets/documents/GTC_en.pdf')">the terms and conditions</a>.</span>
                                                            </label>
                                                            <span class="text-red">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                        <ValidationProvider :rules="{ required: { allowFalse: false } }"  v-slot="{ errors }">
                                                            <label class="custom-control mt-4 custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" name="Accept TransferStatement" v-model="paymentsInfo.acceptTransferStatement">
                                                                <span class="custom-control-label text-dark pl-2">I accept <a class="terms" target="_blank" :href="asset('assets/documents/DataTrasfer_en.pdf')" >the data transfer statement</a>.</span>
                                                            </label>
                                                            <span class="text-red">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                    <ul class=" mb-b-4 ">
                                                        <li class="float-right">
                                                        <button type="submit" class="btn btn-primary  mb-0 mr-2"  :disabled="invalid" >Continue</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </ValidationObserver>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    
</template>

<style>
    .table-bordered td, .table-bordered th {
        border: none;
        border-bottom: 1px solid #e4e6f1;
    }
</style>

<script>

import {
  extend,
  localize,
  configure,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'

import * as rules from 'vee-validate/dist/rules'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

export default {
    props: {
        pInfo: {
            type: Object
        },
        pCountries: {
            type: Object
        },
    },
    components: {
        ValidationProvider,
        ValidationObserver
    },
    methods: {
        asset(path) {
            var base_path = window._asset || '';
            return base_path + path;
        },
        formatPrice(value, currency) {
            let val = value;
            if (currency=='huf') {
                val = (value/1).toFixed(0).replace('.', ',');
            } else {
                val = (value/1).toFixed(2).replace('.', ',');
            }

            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")+' ' + currency.toUpperCase() ;
        },
        setPayType: function (value) {
            this.paymentsInfo.payment_type = value;
        },
        onSubmit() {
            
             this.loading = true;
                axios
                    .post('/api/order/save', this.paymentsInfo)
                    .then(response => {
                        const data = response.data;

                        console.log(data);
                        if (data.errors == false && data.data.paymentUrl) {
                            window.location.href = data.data.paymentUrl;
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        this.errored = true
                    })
                    .finally(() => this.loading = false)

        }
    },
    data () {
        return {
            cart: null,
            paymentsInfo: {
                payment_type:'simplepay',
                name: this.pInfo?.name,
                email: this.pInfo?.email,
                emailConfirmation:'',
                taxnumber: this.pInfo?.taxnumber,
                country: this.pInfo?.country,
                zipcode: this.pInfo?.zipcode,
                city: this.pInfo?.city,
                street: this.pInfo?.street,
                housenr: this.pInfo?.housenr,
                phone: this.pInfo?.phone,
                acceptConditions: null,
                acceptTransferStatement: null
            },
            errored: false
        }
    },
    mounted() {
        this.loading = true;
        axios
            .get('/api/order/')
            .then(response => {
                this.cart = response.data.data;
            })
            .catch(error => {
                console.log(error)
                this.errored = true
            })
            .finally(() => this.loading = false)
    }
}
</script>