require('./bootstrap');

require('bootstrap-autocomplete');

require('bootstrap-select');


require('./search');
require('./cart');

require('./tabs');

require('../assets/plugins/tabs/jquery.multipurpose_tabcontent');

window.Vue = require('vue').default;

import * as VeeValidate from 'vee-validate';
import moment from 'moment'

Vue.use(VeeValidate);


Vue.component('cart-component', require('./components/CartComponent.vue').default);
Vue.component('payments-component', require('./components/PaymentsComponent.vue').default);
Vue.component('confirm-dialog', require('./components/ConfirmDialog.vue').default);
Vue.component('order-component', require('./components/OrderComponent.vue').default);
Vue.component('all-orders-component', require('./components/AllOrdersComponent.vue').default);
Vue.component('user-orders-component', require('./components/UserOrdersComponent.vue').default);


Vue.filter('formatDate', function(value) {
    if (value) {
      return moment(String(value)).format('YYYY-MM-DD hh:mm')
    }
  });

const app = new Vue({
    el: '#app'
});
