<template>
    <div class="container" style="padding-top: 25px;">
        <div class="row">
            <div v-if="loading">Loading...</div>
                <div class="card mb-0">
                    <div class="card-header">
                        <div class="col-8">
                            <h2 class="card-title">Saved orders</h2>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive border-top car-table">
                            <table class="table table-bordered table-hover text-nowrap">
                                <thead>
                                    <tr >
                                        <th>Order Date</th>
                                        <th>Customer</th>
                                        <th>Type</th>
                                        <th>Price</th>
                                        <th>P. Status</th>
                                        <th>O. Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="item in orders.data">
                                    <tr  @click="toggle(item.id)" :class="{ opened: opened.includes(item.id) }">
                                        <td>{{item.created_at | formatDate}}</td>
                                        <td>
                                            {{item.name}} {{item.email}} <br/>
                                            {{item.country}}-{{item.zipcode}} {{item.address}}<br/>
                                            {{item.phone}} 
                                        </td>
                                        <td>
                                            {{item.payment_type}}
                                        </td>
                                        <td>
                                            {{formatPrice(item.price, 'EUR')}}
                                        </td>
                                        <td>
                                            {{item.trans_status}}
                                        </td>
                                        <td>
                                            {{item.status}}
                                        </td>
                                    </tr>
                                     <tr v-if="opened.includes(item.id)">
                                        <td>
                                            <ul>
                                                <li v-if="item.invoice_id>0">
                                                    <a class="btn btn-block btn-primary" v-bind:href="'api/order/'+item.id+'/'+item.uid+'/invoice'" target="_blank">
                                                        <i class="fa fa-eye"></i> View invoice
                                                    </a>
                                                    <br/>
                                                </li>
                                                <li  v-if="item.invoice_id==undefined"> {{item.invoice_id}}<a class="btn btn-block btn-secondary" href="#"  @click="generateInvoice(item)">Generate and send an invoice</a><br/></li>
                                                <li  v-if="item.status!==1"><a class="btn btn-block btn-info" href="#"  @click="generateFulfillment(item)">Order fulfillment</a><br/></li>
                                            </ul>
                                        </td>
                                        <td colspan="5">
                                            <ProductItems :id="item.id" :uid="item.uid"></ProductItems>
                                        </td>
                                    </tr>
                                    </template>
                                </tbody>
                            </table>
                            <pagination :data="orders" v-on:pagination-change-page="getResults"></pagination>
                        </div>
                    </div>
                </div>
        </div>
    </div>
    
</template>

<style>
    .table-bordered td, .table-bordered th {
        border: none;
        border-bottom: 1px solid #e4e6f1;
    }
</style>
    .opened {
        background-color: yellow;
    }
<script>
import ConfirmDialogue from './ConfirmDialog.vue'
import ProductItems from './ProductItems.vue'
import pagination from 'laravel-vue-pagination'

export default {
    components: { ConfirmDialogue, ProductItems, pagination  },
    
    created() {
		this.getResults();
	},

    methods: {
        formatPrice(value, currency) {
            let val = value;
            if (currency=='huf') {
                val = (value/1).toFixed(0).replace('.', ',');
            } else {
                val = (value/1).toFixed(2).replace('.', ',');
            }

            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")+' ' + currency.toUpperCase() ;
        },
        toggle(id) {
    	    const index = this.opened.indexOf(id);
            if (index > -1) {
                this.opened.splice(index, 1)
            } else {
                this.opened.push(id)
            }
        },
        generateInvoice(item){
             axios.post('/api/admin/generate-invoice', {
                    id: item.id,
                    uid: item.uid,
                }).then(() => {
                    
                }).catch(error => {
                   
                });
        },

        generateFulfillment(item){
             axios.post('/api/admin/generate-fulfillment', {
                    id: item.id,
                    uid: item.uid,
                }).then(() => {
                    
                }).catch(error => {
                   
                });
        },
        getResults(page) {
			if (typeof page === 'undefined') {
				page = 1;
			}

            this.loading = true;
            axios
                .get('/api/admin/orders?page=' + page)
                .then(response => {
                    this.orders = response.data.data;
                    console.log(this.orders);
                })
                .catch(error => {
                    console.log(error)
                    this.errored = true
                })
                .finally(() => this.loading = false)

		}
        
    },
    data () {
        return {
            opened: [],
            orders: { },
            loading: true,
            errored: false,
        }
    }
}
</script>