<template>
    <table class="table table-bordered table-hover text-nowrap">
        <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Price</th>
                <th class="text-center">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item in order.items">
                <td>
                    {{ item.name }} <span v-if="item.props.sigTypeCaption">({{item.props.sigTypeCaption}})</span>
                    <ul>
                        <li>
                            {{item.props.name}} <span v-if="item.props.reg_number">({{item.props.reg_number}})</span>
                        </li>
                        <li>
                            {{item.props.company_id}}
                        </li>
                        <li>
                            {{item.props.lang}}
                        </li>
                    </ul>
                </td>
                <td>{{ item.type }}</td>
                <td>{{ formatPrice(item.price, 'EUR') }}</td>
                <td class="text-center" v-if="item.type=='credit-report'">
                    <a v-if="item.result && item.result.report_url"  v-bind:href="item.result.report_url" target="_blank"  class="btn btn-primary btn-sm text-white mb-1" data-toggle="tooltip" data-original-title="Download">
                        <i class="fa fa-download"></i>
                    </a>
                </td>
                <td class="text-center" v-if="item.type=='occsz'">
                    <a v-if="item.result &&  item.result.report_url"  v-bind:href="item.result.report_url" target="_blank"  class="btn btn-primary btn-sm text-white mb-1" data-toggle="tooltip" data-original-title="Download">
                        <i class="fa fa-download"></i>
                    </a>
                </td>
                <td class="text-center" v-if="item.result && item.type=='crefoport-account'">
                    Site url: <a  href="https://www.crefoport.hu/" target="_blank"  class="btn btn-primary btn-sm text-white mb-1" data-toggle="tooltip" data-original-title="Download">
                        <i class="fa fa-link"></i>
                    </a> <br />
                    User name: {{item.result.userID}}<br />
                    Password: {{item.result.password}}<br />
                </td>
            </tr>
        </tbody>                                
    </table>
</template>
<script>
import ConfirmDialogue from './ConfirmDialog.vue'
export default {
    components: { ConfirmDialogue  },
    props: {
        id: {
            type: String
        },
        uid: {
            type: String
        },
    },
    methods: {
        formatPrice(value, currency) {
            let val = value;
            if (currency=='huf') {
                val = (value/1).toFixed(0).replace('.', ',');
            } else {
                val = (value/1).toFixed(2).replace('.', ',');
            }

            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")+' ' + currency.toUpperCase() ;
        },
    },
    data () {
        return {
            order: { items: []},
            loading: true,
            errored: false
        }
    },
    mounted() {
        this.loading = true;
        axios
            .get('/api/order/'+this.id+'/'+this.uid)
            .then(response => {
                this.order = response.data.data;
            })
            .catch(error => {
                console.log(error)
                this.errored = true
            })
            .finally(() => this.loading = false)
    }
}
</script>                                            