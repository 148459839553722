//



$(document).ready(function(){
   updateCartCount(undefined);

   changeOccszProduct();

    $( ".products-form" ).submit(function( event ) {
      const url = $( this ).attr('action');
      axios.post(url, $( this ).serialize() )
         .then(function(response) {
            if (response.data.errors == true) {

               $('#car-error-message').html(response.data.message);
               
               $('#car-error').modal({
                  backdrop: 'static',
                  keyboard: false
              });

               return;
            }

            setCartCount(response.data.data.countItems);


            $('#car-confirm').modal({
               backdrop: 'static',
               keyboard: false
           })
           .on('click', '#go-to-car', function(e) {
               window.location.href = '/cart';
             });
           $("#cancel").on('click',function(e){
            e.preventDefault();
            $('#confirm').modal.model('hide');
           });
         })
         .catch(function(error) {
               console.log(error);
         });

        setCartCount(5);
        event.preventDefault();
      });

 });  

 function updateCartCount(count){
     if (count == undefined) {
        axios.get('/api/cart/count-items')
         .then(function(response) {
            setCartCount(response.data.data);
         })
         .catch(function(error) {
               console.log(error);
         });
     } else {
        setCartCount(count);
     }
 }

 function setCartCount(count){
     $( "#cart-count" ).text(count);
     if (count > 0) {
        $( "#cart-count" ).removeClass('invisible');
     } else {
        $( "#cart-count" ).addClass('invisible');
     }
 }

function changeOccszProduct() {
   $('#price-occsz-certificate-of-registration').hide();
   $('#price-occsz-credit-company-current-info').hide();
   $('#price-occsz-certificate-of-registration').show();

   $('input[type=radio][name=product_id]').change(function() {
      if (this.value == 'occsz-certificate-of-registration') {
         $('#price-occsz-credit-company-current-info').hide();
         $('#price-occsz-certificate-of-registration').show();
      }
      else if (this.value == 'occsz-credit-company-current-info') {
         $('#price-occsz-certificate-of-registration').hide();
         $('#price-occsz-credit-company-current-info').show();
      }
  });
 }      

 
