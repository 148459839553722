<template>
    <div class="container">
        <div class="row">
            <div v-if="loading">Loading...</div>
                <div class="card mb-0">
                    <div class="card-header">
                        <h3 class="card-title">Order Id: {{order.id}}</h3>
                    </div>
                    <div class="card-body">
                
                        <vue-simple-spinner v-if="order.status!=1 && !errored"  size="massive" :message="spinerMessage"></vue-simple-spinner>
                        <div v-if="errored" class="p-25">
                            <h4 class="text-center">{{errorMessage}}</h4>
                        </div>

                        <div v-if="order.status==1"  class="table-responsive border-top car-table">
                            <table class="table table-bordered table-hover text-nowrap">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in order.items">
                                        <td>
                                            {{ item.name }} <span v-if="item.props.sigTypeCaption">({{item.props.sigTypeCaption}})</span>
                                            <ul>
                                                <li>
                                                    {{item.props.name}} <span v-if="item.props.reg_number">({{item.props.reg_number}})</span>
                                                </li>
                                                <li>
                                                    {{item.props.company_id}}
                                                </li>
                                                <li>
                                                    {{item.props.lang}}
                                                </li>
                                            </ul>
                                        </td>
                                        <td>{{ item.type }}</td>
                                        <td class="text-center" v-if="item.type=='credit-report'">
                                            <a v-if="item.result.report_url"  v-bind:href="item.result.report_url" target="_blank"  class="btn btn-primary btn-sm text-white mb-1" data-toggle="tooltip" data-original-title="Download">
                                                <i class="fa fa-download"></i>
                                            </a>
                                        </td>
                                         <td class="text-center" v-if="item.type=='occsz'">
                                            <a v-if="item.result.report_url"  v-bind:href="item.result.report_url" target="_blank"  class="btn btn-primary btn-sm text-white mb-1" data-toggle="tooltip" data-original-title="Download">
                                                <i class="fa fa-download"></i>
                                            </a>
                                        </td>
                                        <td class="text-center" v-if="item.type=='crefoport-account'">
                                            Site url: <a  href="https://www.crefoport.hu/" target="_blank"  class="btn btn-primary btn-sm text-white mb-1" data-toggle="tooltip" data-original-title="Download">
                                                <i class="fa fa-link"></i>
                                            </a> <br />
                                            User name: {{item.result.userID}}<br />
                                            Password: {{item.result.password}}<br />
                                        </td>
                                    </tr>
                                </tbody>                                
                            </table>
                            <h5 class="small">* The html document is not supported by Chrome on Android smartphones</h5>
                        </div>
                    </div>
                </div>
        </div>
    </div>
    
</template>

<style>
    .table-bordered td, .table-bordered th {
        border: none;
        border-bottom: 1px solid #e4e6f1;
    }
</style>

<script>
import Spinner from 'vue-simple-spinner'

export default {
    components: {
        Spinner
    },
    props: {
        id: {
            type: String
        },
        uid: {
            type: String
        },
    },
    data () {
        return {
            order: { items: []},
            timer: '',
            autoUpdateCount: 0,
            loading: true,
            errored: false,
            errorMessage: '',
            spinerMessage: 'Order processing...'
        }
    },
    created () {
        this.fetchOrder();
        this.timer = setInterval(this.fetchOrder, 10000);
    },
    methods: {
        fetchOrder () {
            this.loading = true;
            axios
                .get('/api/order/'+this.id+'/'+this.uid)
                .then(response => {
                    this.autoUpdateCount++;
                    this.spinerMessage = 'Order processing...'+ this.autoUpdateCount.toString()+'/15';
                    console.log(response.data.data);
                    this.order = response.data.data;
                    if (this.order.status == 1)
                        this.cancelAutoUpdate();

                    if (this.autoUpdateCount > 15) {
                        this.cancelAutoUpdate();
                        this.errored = true
                        this.errorMessage = 'An error has occurred, please contact your service provider';
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.cancelAutoUpdate();
                    this.errorMessage = error;
                    this.errored = true
                })
                .finally(() => this.loading = false)
        },
        cancelAutoUpdate () {
            clearInterval(this.timer);
        }
    },
    beforeDestroy () {
      this.cancelAutoUpdate();
    }
}
</script>