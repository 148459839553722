<template>
    <div class="container">
        <div class="row">
            <div v-if="loading">Loading...</div>
                <div class="card mb-0">
                    <div class="card-header">
                            <div class="col-8">
                                <h3 class="card-title">Cart Contents</h3>
                            </div>
                            <div class="col-4 text-right">
                                <button type="button" class="btn btn-info m-b-10 " @click="clear">
                                    <i class="fa fa-trash-o"></i> 
                                    Empty cart
                                </button>
                            </div>
                    </div>
                    <div class="card-body">

                        <div class="table-responsive border-top car-table">
                            <table class="table table-bordered table-hover text-nowrap">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Type</th>
                                        <th class="text-center">Price</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in cart.items">
                                        <td>
                                            {{ item.product.name }} <span v-if="item.props.sigTypeCaption">({{item.props.sigTypeCaption}})</span>
                                            <ul>
                                                <li>
                                                    {{item.props.name}}
                                                </li>
                                                <li>
                                                    {{item.props.company_id}}
                                                </li>
                                                <li>
                                                    {{item.props.lang}}
                                                </li>
                                            </ul>

                                        </td>
                                        <td>{{ item.product.type }}</td>
                                        <td class="font-weight-semibold fs-16 text-right">
                                            {{ formatPrice(item.product.price.price*(1+item.product.price.vat/100), item.product.price.currency) }}
                                        </td>
                                        <td class="text-center">
                                            <button type="button" class="btn btn-secondary btn-sm text-white mb-1 " @click="deleteItem(item)" >
                                                <i class="fa fa-trash-o"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="3">Items on Cart:</td>
                                        <td class="text-right">{{cart.countItems}} pc</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3">Total Qty:</td>
                                        <td class="text-right">{{ formatPrice(cart.totalPrice, 'EUR') }}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
        </div>
    </div>
    
</template>

<style>
    .table-bordered td, .table-bordered th {
        border: none;
        border-bottom: 1px solid #e4e6f1;
    }
</style>

<script>
import ConfirmDialogue from './ConfirmDialog.vue'
export default {
    components: { ConfirmDialogue  },
    methods: {
        formatPrice(value, currency) {
            let val = value;
            if (currency=='huf') {
                val = (value/1).toFixed(0).replace('.', ',');
            } else {
                val = (value/1).toFixed(2).replace('.', ',');
            }

            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")+' ' + currency.toUpperCase() ;
        },

        async deleteItem(item) { 
            console.log(item.id);
            const ok = await this.$refs.confirmDialog.show({
                title: 'Delete Page',
                message: 'Are you sure you want to delete this page? It cannot be undone.',
                okButton: 'Delete Forever',
            })
            console.log('ok');
            console.log(ok);
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                alert('You have successfully delete this page.')
            } else {
                alert('You chose not to delete this page. Doing nothing now.')
            }
        },

        clear() {
             this.loading = true;
                axios
                    .put('/api/cart/clean')
                    .then(response => {
                        this.cart = response.data.data;
                        if (this.cart.items.length == 0) {
                            location.reload();
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        this.errored = true
                    })
                    .finally(() => this.loading = false)
        }
    },
    data () {
        return {
        cart: { items: []},
        loading: true,
        errored: false
        }
    },
    mounted() {
        this.loading = true;
        axios
            .get('/api/cart')
            .then(response => {
                this.cart = response.data.data;
            })
            .catch(error => {
                console.log(error)
                this.errored = true
            })
            .finally(() => this.loading = false)
    }
}
</script>