
$(document).ready(function(){

    $('#search-text').autoComplete({
                resolver: 'custom',
                formatResult: function (item) {
                    return {
                        value:  item.id,
                        text: item.text,
                        html: formatRepo(item),
                    };
                },
                noResultsText: 'Your search returned no results',
                events: {
                    search: function (qry, callback) {
                        axios.get('/api/companies/search', {
                            params: {
                               q: $('#search-text').val(),
                               countryCode:$('#search-country-code').val()
                            }
                        })
                        .then(function(response) {
                            callback(response.data);
                            $('#search-text', this).show();
                        })
                        .catch(function(error) {
                            console.log(error);
                        });
                       
                    },
                },
                
    });

    $('#search-text').on('autocomplete.select', function (evt, item) {
        window.location.href = window.location.origin + '/' + item.id;
    });


});

function formatRepo(item) {
    return '<div class="img"><span class="flag flag-'+item.countryCode+'"></span></div><div class="text">'+ item.text + '</div>';
}